import EditIcon from '@mui/icons-material/Edit'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardControlKeyIcon from '@mui/icons-material/KeyboardControlKey'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import TimerIcon from '@mui/icons-material/Timer'
import { Button, Card, CardContent, Divider, Grid, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material'
import WorkOrderReleaseTaskTimeEntryAddEditDialog from 'components/WorkOrderReleaseTaskTimeEntryAddEditDialog'
import { useAuth } from 'context'
import * as api from 'controllers'
import * as controllers from 'controllers'
import { WorkOrderReleaseTaskDto, WorkOrderReleaseTaskTimeEntryDto } from 'dtos'
import { enqueueSnackbar } from 'notistack'
import WorkOrderReleaseTaskAddEditDialog from 'pages/workOrders/id/WorkOrderReleaseTaskAddEditDialog'
import React, { useState } from 'react'

interface TaskListCardProps {
  task: WorkOrderReleaseTaskDto
  refreshTasks: () => void
}

export default function TaskListCard({ task, refreshTasks }: TaskListCardProps) {
  const [timeEntry, setTimeEntry] = useState<WorkOrderReleaseTaskTimeEntryDto>(new WorkOrderReleaseTaskTimeEntryDto())
  const [timeEntryDialogOpen, setTimeEntryDialogOpen] = useState<boolean>(false)

  const [moveUpPriority, setMoveUpPriority] = useState<boolean>(false)

  const [workOrderReleaseTask, setWorkOrderReleaseTask] = useState<WorkOrderReleaseTaskDto>(new WorkOrderReleaseTaskDto())

  const [isCreatingTimeEntry, setIsCreatingTimeEntry] = useState<boolean>(false)

  const [workOrderReleaseTaskAddEditDialogOpen, setWorkOrderReleaseTaskAddEditDialogOpen] = useState<boolean>(false)

  const [workOrderReleaseTasks, setWorkOrderReleaseTasks] = useState<WorkOrderReleaseTaskDto[]>([])

  const [isSavingWorkOrderReleaseTask, setIsSavingWorkOrderReleaseTask] = useState<boolean>(false)

  const {
    permissions: { GENERAL_MANAGER }
  } = useAuth()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <WorkOrderReleaseTaskAddEditDialog
        initialValues={workOrderReleaseTask}
        isLoading={isSavingWorkOrderReleaseTask}
        onClose={() => {
          setWorkOrderReleaseTaskAddEditDialogOpen(false)
        }}
        onSave={values => {
          setIsSavingWorkOrderReleaseTask(true)

          controllers
            .updateWorkOrderReleaseTask(values)
            .then(() => {
              setWorkOrderReleaseTaskAddEditDialogOpen(false)
              enqueueSnackbar('Task Saved Successfully!', { variant: 'success' })
            })
            .finally(() => {
              setIsSavingWorkOrderReleaseTask(false)
            })
        }}
        open={workOrderReleaseTaskAddEditDialogOpen}
        workOrderReleaseTasks={workOrderReleaseTasks}
      />
      <WorkOrderReleaseTaskTimeEntryAddEditDialog
        open={timeEntryDialogOpen}
        initialValues={timeEntry}
        isLoading={isCreatingTimeEntry}
        onClose={() => {
          setTimeEntryDialogOpen(false)
          setTimeEntry(new WorkOrderReleaseTaskTimeEntryDto())
        }}
        onSave={values => {
          setIsCreatingTimeEntry(true)
          api
            .createWorkOrderReleaseTaskTimeEntry(values)
            .then(() => {
              enqueueSnackbar('Time Entry Saved Successfully!', { variant: 'success' })
              setTimeEntryDialogOpen(false)
            })
            .finally(() => {
              setIsCreatingTimeEntry(false)
            })
        }}
      />
      <Card>
        <CardContent>
          <Grid container spacing={1} alignItems='flex-start'>
            <Grid item xs={12} container alignItems='center' justifyContent='space-between'>
              <Grid item xs={12} sm={true} container spacing={0} alignItems='center'>
                <Grid item>
                  <Typography fontWeight='bold' variant='h5' sx={{ pr: 1 }}>
                    {task.workOrderRelease?.workOrder?.company?.name +
                      ' - ' +
                      task.workOrderRelease?.workOrder?.project?.name +
                      ' - #' +
                      task.workOrderRelease?.workOrder?.workOrderNumber}
                  </Typography>

                  <Typography variant='body2' color='textSecondary'>
                    {task.workOrderRelease?.releaseTitle}{' '}
                    {task.workOrderRelease?.releaseNumber ? `- ${task.workOrderRelease.releaseNumber}` : ''}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12} sm='auto' container spacing={1} alignItems='center'>
                <Grid item>
                  <Button
                    endIcon={<TimerIcon />}
                    onClick={() => {
                      setTimeEntry({
                        ...new WorkOrderReleaseTaskTimeEntryDto(),
                        workOrderReleaseTask: task
                      })
                      setTimeEntryDialogOpen(true)
                    }}
                    size='small'
                    variant='text'
                  >
                    LOG TIME
                  </Button>
                </Grid>

                <Grid item xs={12} sm='auto'>
                  {GENERAL_MANAGER && (
                    <Button
                      endIcon={<MoreVertIcon />}
                      aria-controls={open ? 'menu' : undefined}
                      aria-haspopup='true'
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                      size='small'
                      variant='text'
                    ></Button>
                  )}
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'menu-button'
                    }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        setMoveUpPriority(true)
                        api
                          .putPrioritize({ ...task, priority: task.priority! - 999 })
                          .then(() => {
                            refreshTasks()
                            enqueueSnackbar('Task Has Been Set As Top Priority', { variant: 'success' })
                          })
                          .finally(() => {
                            setMoveUpPriority(false)
                          })
                      }}
                    >
                      <ListItemIcon>
                        <KeyboardDoubleArrowUpIcon fontSize='small' color='primary' />
                      </ListItemIcon>
                      Move to Top
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        setMoveUpPriority(true)
                        api
                          .putPrioritize({ ...task, priority: task.priority! - 1 })
                          .then(() => {
                            refreshTasks()
                            enqueueSnackbar('Task Priority Has Been Increased', { variant: 'success' })
                          })
                          .finally(() => {
                            setMoveUpPriority(false)
                          })
                      }}
                    >
                      <ListItemIcon>
                        <KeyboardControlKeyIcon fontSize='small' color='primary' />
                      </ListItemIcon>
                      Move Up
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        setMoveUpPriority(true)
                        api
                          .putPrioritize({ ...task, priority: task.priority! + 1 })
                          .then(() => {
                            refreshTasks()
                            enqueueSnackbar('Task Priority Has Been Decreased', { variant: 'success' })
                          })
                          .finally(() => {
                            setMoveUpPriority(false)
                          })
                      }}
                    >
                      <ListItemIcon>
                        <KeyboardArrowDownIcon fontSize='small' color='primary' />
                      </ListItemIcon>
                      Move Down
                    </MenuItem>

                    <Divider />
                    <MenuItem
                      onClick={() => {
                        setMoveUpPriority(true)
                        api
                          .putPrioritize({ ...task, priority: task.priority! + 999 })
                          .then(() => {
                            refreshTasks()
                            enqueueSnackbar('Task Has Been Set As Last Priority', { variant: 'success' })
                          })
                          .finally(() => {
                            setMoveUpPriority(false)
                          })
                      }}
                    >
                      <ListItemIcon>
                        <KeyboardDoubleArrowDownIcon fontSize='small' color='primary' />
                      </ListItemIcon>
                      Move to Bottom
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        setWorkOrderReleaseTask(task)
                        setWorkOrderReleaseTaskAddEditDialogOpen(true)
                      }}
                    >
                      <ListItemIcon>
                        <EditIcon fontSize='small' color='primary' />
                      </ListItemIcon>
                      Edit
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={3}>
              <Typography variant='body2'>Priority</Typography>
              <Typography color='primary' variant='body1'>
                {task.priority}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography variant='body2'>Hours To Date</Typography>
              <Typography color='primary' variant='body1'>
                {task.hoursToDate}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography variant='body2'>Start Date</Typography>
              <Typography color='primary' variant='body1'>
                {task.startDate || 'Not Started'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography variant='body2'>Days Since Last Activity</Typography>
              <Typography color='primary' variant='body1'>
                {task.DaysSinceLastActivity || 'No Activity'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography variant='body2'>Description of Work to be Done</Typography>
              <Typography color='primary' variant='body1'>
                {task.workToBeDone}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}
