import { Card, CardContent, Grid, MenuItem, TextField, Typography } from '@mui/material'
import { List } from 'components/list'
import { useAuth } from 'context'
import * as api from 'controllers'
import * as controllers from 'controllers'
import { CompanyDto, PersonDto, StatusCount, WorkOrderReleaseTaskDto } from 'dtos'
import { useLoadingState, useQuery } from 'hooks'
import { WorkOrderReleaseTasksParameters } from 'parameters'
import { useEffect, useState } from 'react'
import { useDebounce } from 'utils'
import TaskListCard from './TaskListCard'

export default function Tasks() {
  const query = useQuery()
  const { user } = useAuth()

  const [isGettingTeamMembers, setIsGettingTeamMembers] = useState<boolean>(false)
  const [isGettingProjectMangers, setIsGettingProjectManagers] = useState<boolean>(false)
  const [isGettingCompanies, setIsGettingCompanies] = useState<boolean>(false)

  const [teamMembers, setTeamMembers] = useState<PersonDto[]>([])
  const [projectManagers, setProjectManagers] = useState<PersonDto[]>([])
  const [companies, setCompanies] = useState<CompanyDto[]>([])

  const [tasks, setTasks] = useState<WorkOrderReleaseTaskDto[]>([])

  const [count, setCount] = useState<number>(0)

  const [parameters, setParameters] = useState<WorkOrderReleaseTasksParameters>({
    page: 0,
    pageSize: 10,
    teamMemberId: '',
    projectManagerId: '',
    search: query.get('search') ?? '',
    statuses: ['isOpen', 'isComplete', 'needsEstimation', 'needsDueDate'],
    activeOnly: true,
    companyId: '',
    workOrderReleaseId: '',
    order: 'asc',
    orderBy: 'priority'
  })

  const [loadingState, setLoadingState] = useLoadingState({
    isGettingTasks: false,
    isCreatingTask: false
  })

  const getTasks = useDebounce((parameters: WorkOrderReleaseTasksParameters) => {
    setLoadingState('isGettingTasks', true)
    controllers
      .getWorkOrderReleaseTasks(parameters)
      .then(({ totalCount, value }) => {
        setCount(totalCount ?? 0)
        setTasks(value)
      })
      .finally(() => {
        setLoadingState('isGettingTasks', false)
      })
  }, 300)

  useEffect(() => {
    getTasks(parameters)
  }, [parameters])

  // I dont think permissions are needed on this screen? -Mdsommer 9/12/2024
  const {
    permissions: { WORK_ORDER_TASKS_ADD_EDIT }
  } = useAuth()

  useEffect(() => {
    setIsGettingTeamMembers(true)
    api
      .getPeople({ page: 0, pageSize: 999, permission: 'TASKS', statuses: ['isUser'] })
      .then(({ value }) => {
        setTeamMembers(value)
      })
      .catch(() => {})
      .finally(() => {
        setIsGettingTeamMembers(false)
      })

    setIsGettingProjectManagers(true)
    api
      .getPeople({
        page: 0,
        pageSize: 999,
        permission: 'PROJECT_MANAGER',
        statuses: ['isUser']
      })
      .then(({ value }) => {
        setProjectManagers(value)
      })
      .catch(() => {})
      .finally(() => {
        setIsGettingProjectManagers(false)
      })
  }, [])

  useEffect(() => {
    setParameters({
      ...parameters,
      teamMemberId: user!.id
    })
  }, [user])

  useEffect(() => {
    if (parameters.teamMemberId) {
      setIsGettingCompanies(true)
      api
        .getCompanies({ page: 0, pageSize: 999, teamMemberId: parameters.teamMemberId })
        .then(({ value }) => {
          setCompanies(value)
        })
        .catch(() => {})
        .finally(() => {
          setIsGettingCompanies(false)
        })
    }
  }, [parameters.teamMemberId])

  return (
    <>
      <Grid container spacing={1} alignItems='center'>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography fontWeight='bold' variant='h5'>
                Tasks
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={1} alignItems='center' justifyContent='space-between'>
                <Grid item xs={12} sm='auto'>
                  <TextField
                    label='Team Member'
                    onChange={e => {
                      setParameters({
                        ...parameters,
                        teamMemberId: e.target.value,
                        projectManagerId: '',
                        companyId: ''
                      })
                    }}
                    select
                    sx={{ minWidth: 240 }}
                    value={parameters.teamMemberId || ''}
                  >
                    {teamMembers.map(teamMember => (
                      <MenuItem key={teamMember.id} value={teamMember.id}>
                        {teamMember.firstName} {teamMember.lastName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm='auto'>
                  <TextField
                    label='Project Manager'
                    onChange={e => {
                      setParameters({ ...parameters, projectManagerId: e.target.value })
                    }}
                    select
                    sx={{ minWidth: 240 }}
                    value={parameters.projectManagerId || ''}
                  >
                    {projectManagers.map(projectManager => (
                      <MenuItem key={projectManager.id} value={projectManager.id}>
                        {projectManager.firstName} {projectManager.lastName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item xs={12} sm='auto'>
                  <TextField
                    label='Company'
                    onChange={e => {
                      setParameters({ ...parameters, companyId: e.target.value })
                    }}
                    select
                    sx={{ minWidth: 240 }}
                    value={parameters.companyId || ''}
                  >
                    {companies.map(company => (
                      <MenuItem key={company.id} value={company.id}>
                        {company.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <Grid>
            {tasks.map(task => (
              <Grid item xs={12} sx={{ mt: 1 }} key={task.id}>
                <TaskListCard
                  refreshTasks={() => {
                    getTasks(parameters)
                  }}
                  task={task}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <List
        pagination={{
          count,
          onPageChange: (page: number) => {
            setParameters({ ...parameters, page })
          },
          onPageSizeChange: (pageSize: number) => {
            setParameters({ ...parameters, pageSize })
          },
          page: parameters.page,
          pageSize: parameters.pageSize
        }}
        sortedByDescription=''
      ></List>
    </>
  )
}
